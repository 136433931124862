
import { useAPI } from "@/use";
import { defineComponent } from "vue-demi";

export default defineComponent({
  name: 'OwnerAuth',
  props: {
    _id: {
      type: String,
      required: true
    }
  },
  async created() {
    const result = await useAPI().common.auth.authenticationOwnerMethod(this._id);
    this.$store.commit('loginOwner', { accessToken: result.data.accessToken, query: this.$route.query });
  }
})
